<template>
  <footer class="landing-page__footer landing-page__section">
    <div class="landing-page__section-wrapper landing-page__footer-wrapper">
      <div class="landing-page__col">
        <ul
          v-if="showCreditCardList"
          class="landing-page__payments-list"
        >
          <li class="landing-page__payments-item">
            <img
              src="@/assets/img/landingPage/logo-visa.svg"
              class="landing-page__payments-icon"
              alt="Visa"
            >
          </li>

          <li class="landing-page__payments-item">
            <img
              src="@/assets/img/landingPage/logo-master.svg"
              class="landing-page__payments-icon"
              alt="Mastercard"
            >
          </li>

          <li class="landing-page__payments-item">
            <img
              src="@/assets/img/landingPage/logo-elo.svg"
              class="landing-page__payments-icon"
              alt="Elo"
            >
          </li>

          <li class="landing-page__payments-item">
            <img
              src="@/assets/img/landingPage/logo-american-express.svg"
              class="landing-page__payments-icon"
              alt="Diners"
            >
          </li>
        </ul>
      </div>

      <div class="landing-page__col">
        <img
          :alt="partner.name"
          :src="require(`@/assets/img/logo/${partner.logo.footer}`)"
          class="landing-page__logo landing-page__logo--footer"
        >

        <p class="landing-page__text landing-page__footer-text">
          Copyright © {{ partner.name }}. Todos os direitos reservados.
        </p>
      </div>

      <button
        aria-label="Ir para o topo"
        class="landing-page__scroll-top"
        @click.prevent="scrollToSection()"
      >
        <i class="icon icon-upward landing-page__scroll-top-icon" />
      </button>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import scrollToSection from '@/mixins/scrollToSection';

export default {
  name: 'Footer',

  mixins: [scrollToSection],

  computed: {
    ...mapGetters('partner', ['partner', 'paymentMethods']),

    showCreditCardList() {
      // return this.paymentMethods.some((method) => method.name === 'creditCard');
      return true;
    },
  },
};
</script>
